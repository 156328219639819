import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'
import DataTitle from '../components/DataTitle'
import DataBoxes from '../components/DataBoxes'
import { useMediaQuery } from '../customHooks/useMediaQuery'

const Home = ({ allCountries, setCountry, apiData }) => {
  const [selectedCountry, setSelectedCountry] = useState('')
  const isMobileView = useMediaQuery('(max-width: 1024px)')

  const handleCountry = (e) => {
    setSelectedCountry(e.target.value)
    setCountry(e.target.value)
  }

  const handleClick = () => {
    setSelectedCountry('')
    setCountry('')
  }

  return (
    <Container>
      {selectedCountry ? (
        <>
          <DataTitle apiData={apiData} country={selectedCountry} />
          <DataBoxContainer>
            <DataBoxes
              name='Cases'
              apiData={apiData}
              country={selectedCountry}
            />
            <DataBoxes
              name='Deaths'
              apiData={apiData}
              country={selectedCountry}
            />
          </DataBoxContainer>
        </>
      ) : (
        <>
          <DataTitle apiData={apiData} />
          <DataBoxContainer>
            <DataBoxes name='Cases' apiData={apiData} />
            <DataBoxes name='Deaths' apiData={apiData} />
          </DataBoxContainer>
        </>
      )}

      <ContainerItems>
        {isMobileView ? (
          <select
            value={selectedCountry}
            onChange={handleCountry}
            style={{ width: '95%' }}
          >
            <option>Select Country</option>
            {allCountries?.map((country) => (
              <option key={country}>{country}</option>
            ))}
          </select>
        ) : (
          <select
            value={selectedCountry}
            onChange={handleCountry}
            style={{ width: '98%' }}
          >
            <option>Select Country</option>
            {allCountries?.map((country) => (
              <option key={country}>{country}</option>
            ))}
          </select>
        )}

        <Button type='submit' onClick={handleClick}>
          Clear Country
        </Button>
      </ContainerItems>
    </Container>
  )
}

export default Home

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`
const ContainerItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  > select {
    border: 1px solid grey;
    border-radius: 3px;
    padding: 10px;
    outline: none;
  }
  > button {
    max-width: 30%;
    border: 1px solid grey;
    background-color: #083836;
    color: white;
    margin-top: 40px;
    align-items: flex-start;
  }
`
const DataBoxContainer = styled.div`
  display: flex;
  width: 100%;
`
