import React from 'react'
import styled from 'styled-components/macro'

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo>
        <HeaderTitle>
          <i class='fa fa-viruses'></i>Covid-19 tracker
        </HeaderTitle>
        <HeaderApiInfo>
          <p>
            API by
            <span>
              <a href='https://covid19api.com' target='_blank' rel='noreferrer'>
                covid19api.com
              </a>
            </span>
          </p>
        </HeaderApiInfo>
      </HeaderLogo>
    </HeaderContainer>
  )
}

export default Header

const HeaderContainer = styled.div`
  display: flex;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;

  background-color: var(--covid-color);
  color: white;
`
const HeaderLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 30px;
  > i {
    margin-left: auto;
    margin-right: 10px;
  }
`
const HeaderApiInfo = styled.div`
  align-items: center;

  > p > span {
    margin-left: 10px;
  }
  > p > span > a {
    color: lightgrey;
    text-decoration: none;
    :hover {
      opacity: 0.8;
    }
  }
`
