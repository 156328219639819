import React from 'react'
import styled from 'styled-components/macro'
import getCountryDetails from '../helper/getCountryDetails'

const DataBoxes = ({ name, apiData, country }) => {
  const globalDataDisplay = () => {
    return (
      <>
        <h1>{name}</h1>
        <p>{`New : ${
          name === 'Cases'
            ? apiData?.Global?.NewConfirmed
            : apiData?.Global?.NewDeaths
        }`}</p>
        <p>{`Total : ${
          name === 'Cases'
            ? apiData?.Global?.TotalConfirmed
            : apiData?.Global?.TotalDeaths
        }`}</p>
      </>
    )
  }

  const countryDataDisplay = () => {
    return (
      <>
        <h1>{name}</h1>
        <p>{`New : ${
          name === 'Cases'
            ? getCountryDetails(apiData, country)?.NewConfirmed
            : getCountryDetails(apiData, country)?.NewDeaths
        }`}</p>
        <p>{`Total : ${
          name === 'Cases'
            ? getCountryDetails(apiData, country)?.TotalConfirmed
            : getCountryDetails(apiData, country)?.TotalDeaths
        }`}</p>
      </>
    )
  }

  return (
    <Container>
      {country ? countryDataDisplay() : globalDataDisplay()}
    </Container>
  )
}

export default DataBoxes
const Container = styled.div`
  flex: 1;
  width: 45%;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 20px 10px;
  border-radius: 10px;
  > h1 {
    color: #1e2a78;
    font-weight: bold;
  }
`
