import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled from 'styled-components/macro'
import Header from './components/Header'
import Home from './screens/Home'
import axios from 'axios'
function App() {
  const [apiData, setApiData] = useState([])
  const [country, setCountry] = useState('')
  const [allCountries, setAllCountries] = useState([])

  useEffect(() => {
    async function getApiData() {
      const { data } = await axios.get('https://api.covid19api.com/summary')
      setApiData(data)
      const countries = data?.Countries?.map((c) => c.Country)
      setAllCountries(countries)
    }
    getApiData()
  }, [country])

  return (
    <>
      <Router>
        <Header />

        <AppBody>
          <Switch>
            <Route path='/' exact>
              <Home
                allCountries={allCountries}
                setCountry={setCountry}
                apiData={apiData}
              />
            </Route>
          </Switch>
        </AppBody>
      </Router>
    </>
  )
}

export default App

const AppBody = styled.div`
  display: flex;
  max-width: 960px;
  /* align-items: center;
  justify-content: center; */
  margin-top: 85px;
  margin: 0 auto;
`
