import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import getCountryDetails from '../helper/getCountryDetails'

const DataTitle = ({ apiData, country }) => {
  const titleDataDisplay = () => {
    return (
      <>
        <h1>
          {country ? getCountryDetails(apiData, country)?.Country : 'Global'}
        </h1>
        <p>
          {country
            ? moment(getCountryDetails(apiData, country)?.Date).format(
                'MMMM Do YYYY, h:mm:ss a'
              )
            : moment(apiData?.Global?.Date).format('MMMM Do YYYY, h:mm:ss a')}
        </p>
      </>
    )
  }
  return <Container>{titleDataDisplay()}</Container>
}

export default DataTitle

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  > h1 {
    color: #1e2a78;
    font-weight: bold;
    margin-bottom: 5px;
  }
`
